export const REVIEWS_EVENT_NAMES = {
    REVIEW_CARD_ALL_REVIEW: `review_card_all_review`,
    MODEL_CLOSE: `reviews_modal_close`,
    SHOW_MORE_REVIEWS: `show_more_reviews_link`,
    REVIEWS_PAGINATION: `reviews_pagination`,
    CARD_RATING: `review_card_rating`,
    RATING_HERO_IMAGE: `review_rating_hero_image`,
};
export const RIBBONS_EVENT_NAMES = {
    CLOSE_RIBBON: 'dynamic-ribbon-close-button'
};
