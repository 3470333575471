/* eslint-disable react/no-danger */
/** @jsx h */
import { h } from 'preact';
import { useState } from 'preact/hooks';
import { RIBBONS_EVENT_NAMES } from 'constants/trackpoint-events';
import Link from 'ssr/components/common/link';
const DynamicRibbonSection = ({ mainCopy, cta, }) => {
    const [isRibbonVisible, setIsRibbonVisible] = useState(true);
    if (!isRibbonVisible) {
        return null;
    }
    return (h("aside", { className: 'dynamic-ribbon referrals-banner' },
        h("div", { className: "container\n                        d-flex\n                        align-items-center\n                        justify-content-between\n                        mx-auto\n                        px-3\n                        dynamic-ribbon__inner" },
            h("strong", { dangerouslySetInnerHTML: { __html: mainCopy } }),
            h("div", { className: "d-flex align-items-center" },
                cta && cta.fields && (h(Link, { target: cta.fields.openInANewTab ? '_blank' : '_self', href: cta.fields.link, className: "btn btn--secondary\n                text-uppercase\n                text-decoration-none\n                rounded-0\n                dynamic-ribbon__link", "data-track": JSON.stringify({
                        type: "click",
                        event: cta.fields.eventName,
                        event_properties: {
                            lp_cta: { label: cta.fields.buttonText },
                            sectionName: "ribbon",
                            uiComponent: "componentButton"
                        },
                    }) }, cta.fields.buttonText)),
                h("button", { onClick: () => { setIsRibbonVisible(false); }, className: "d-flex\n                        align-items-center\n                        justify-content-center\n                        margin-left-small\n                        border-0\n                        dynamic-ribbon__close", type: 'button', "data-track": JSON.stringify({
                        type: `click`,
                        event: RIBBONS_EVENT_NAMES.CLOSE_RIBBON
                    }) }, "\u00D7")))));
};
export default DynamicRibbonSection;
